<template>
  <div class="info" id="watermark">
    <van-popup
      v-model="locationDateShow"
      position="bottom"

    >
      <van-datetime-picker
        v-model="dataForm.checkTime"
        type="datetime"
        title="请选择"
        :formatter="formatDate"
        @cancel="timeCancel"
        @confirm="timeConfirm"
      />
    </van-popup>
    <van-popup
      v-model="checkResultShow"
      position="bottom"

    >
      <van-picker
        title="检查结果"
        value-key="label"
        show-toolbar
        :columns="checkResultList"
        @confirm="checkResultConfirm"
        @cancel="checkResultShow = !checkResultShow"
      />
    </van-popup>
    <top-bar :title="'检查详情'" :left="true"></top-bar>
    <van-cell class="custom">
      <template #title>
        <span class="custom-title">检查详情</span>
      </template>
    </van-cell>
    <van-cell
      clearable
      clear-trigger="always"
      title="设备名"
      :value="dataForm.deviceStr" />
    <van-cell
      title="检查结果"
      :is-link="changeOrDetail"
      :value="dataForm.checkResult == 1 ? '正常' : '不正常'"
      @click="checkResultShow = changeOrDetail"
    />
    <van-cell
      title="检查日期"
      :is-link="changeOrDetail"
      :value-class="{'value-common':!checkTimeStr}"
      :value="checkTimeStr || '请选择'"
      @click="locationDateShow = changeOrDetail"
    />
    <van-field
      rows="3"
      autosize
      class="info-title"
      clearable
      clear-trigger="always"
      v-model="dataForm.checkDes"
      label="备注说明"
      :readonly="!changeOrDetail"
      placeholder="请输入"
      input-align="right"
    />
    <van-row class="uploadBox" style="background-color: #fff;">
      <van-col :span="24">
        <!-- <van-uploader v-model="solveFileList" :deletable="false" :show-upload="false"/> -->
        <div class="upload" style="margin-left:15px">
          <upload-file
            :file-list="fileList"
            :del-btn-show="changeOrDetail"
            :upload-btn-show="changeOrDetail"
            @filesUpload="filesUpload"
            :upload-path="uploadPath"
            :file-type="'image'"/>
        </div>
      </van-col>
    </van-row>
    <van-row v-if="!changeOrDetail" class="btns">
      <van-col :span="24">
        <van-button plain type="danger" size="large" @click="deleteInfo" style="background-color:transparent">删除</van-button>
      </van-col>
    </van-row>
    <van-row v-else class="btns" gutter="10">
      <van-col :span="12">
        <van-button plain type="danger" size="large" @click="cancelInfo" style="background-color:transparent">取消</van-button>
      </van-col>
      <van-col :span="12">
        <van-button type="info" size="large" @click="submit">完成</van-button>
      </van-col>
    </van-row>
  </div>
</template>
<script>
import TopBar from "@/components/topBar/topBar";
import { formatterDateTimeWithOutSec } from "@/utils/utils";
import uploadFile from '@/components/upload/uploadFile'
import {getImageStream} from '@/utils/index'
import { isNumber } from "@/utils/validate";
export default {
  components: { TopBar, uploadFile },
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    console.log(this.$route.query);
    this.dataForm.deviceId = this.$route.query.deviceId;
    if (this.$route.query.id) {
      (this.changeOrDetail = false), (this.dataForm.id = this.$route.query.id);
      this.getInfo();
    }
    if (this.$route.query.name) {
      this.dataForm.deviceStr = this.$route.query.name;
      this.dataForm.checkTime = new Date();
      this.checkTimeStr = formatterDateTimeWithOutSec(this.dataForm.checkTime);
    }
  },
  data() {
    return {
      uploadPath: 'files/device/check/temporary',
      checkTimeStr: "",
      locationDateShow: false,
      checkResultShow: false,
      changeOrDetail: true,
      checkResultList: [
        { value: "1", label: "正常" },
        { value: "2", label: "不正常" },
      ],
      fileList: [],
      dataForm: {
        id: "",
        deviceId: "",
        deviceStr: "",
        checkResult: 1,
        checkTime: "",
        checkDes: "",
        createUserId: "",
        createTime: "",
        devicesCheckFileList: [],
        fileMessages: [],
      },
    };
  },
  methods: {
    filesUpload (files) {
      this.dataForm.fileMessages = files
    },
    checkResultConfirm(value) {
      this.dataForm.checkResult = value.value;
      this.checkResultShow = false;
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
    timeCancel() {
      this.locationDateShow = false;
    },
    timeConfirm(value) {
      if (value) {
        this.checkTimeStr = formatterDateTimeWithOutSec(value);
        this.dataForm.checkTime = formatterDateTimeWithOutSec(value);
      }
      this.locationDateShow = false;
    },
    submit() {
      this.dataForm.checkTime=formatterDateTimeWithOutSec(this.dataForm.checkTime)
      this.$http({
        url: this.$http.adornUrl("/wxapp/device/check/save"),
        method: "post",
        data: this.dataForm,
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$toast.success("新增成功");
          this.$router.go(-1);
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
    getInfo() {
      this.$http({
        url: this.$http.adornUrl("/wxapp/device/check/info"),
        method: "post",
        params: this.$http.adornParams(
          {
            id: this.dataForm.id,
          },
          false
        ),
      }).then(({ data }) => {
        console.log(data);
        if (data.code == 0) {
          (this.dataForm.deviceId = data.deviceCheck.deviceId || ""),
            (this.dataForm.deviceStr = data.deviceCheck.deviceStr || ""),
            (this.dataForm.checkResult = data.deviceCheck.checkResult || 1),
            (this.dataForm.checkTime =
              new Date(data.deviceCheck.checkTime) || ""),
            (this.checkTimeStr = data.deviceCheck.checkTime || ""),
            (this.dataForm.checkDes = data.deviceCheck.checkDes || ""),
            (this.dataForm.createUserId = data.deviceCheck.createUserId || ""),
            (this.dataForm.createTime = data.deviceCheck.createTime || ""),
            (this.dataForm.devicesCheckFileList =
              data.deviceCheck.devicesCheckFileList || "")
            if (data.deviceCheck.devicesCheckFileList) {
              data.deviceCheck.devicesCheckFileList.map((item) => {
                let url = {
                  uid: this.fileList.length,
                  id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                  status: 'done',//uploading上传中，done上传完成，error上传失败
                  fileType: 1,
                  relativePath: item.filePath,
                  url: getImageStream(item.filePath),
                  realUrl: item.filePath.replace(/\\/g, "/")
                }
                this.fileList.push(url);
              })
            }
            console.log(this.fileList)
            this.$toast.clear();
        } else {
          this.$toast.clear();
          this.$toast.fail(data.msg);
        }
      });
    },
    deleteInfo() {
      this.$dialog
        .confirm({
          message: "确定删除？",
        })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl("/wxapp/device/check/delete"),
            method: "post",
            params: this.$http.adornParams(
              {
                ids: this.dataForm.id,
              },
              false
            ),
          }).then(({ data }) => {
            console.log(data);
            if (data.code == 0) {
              this.$toast.clear();
              this.$router.go(-1);
            } else {
              this.$toast.clear();
              this.$toast.fail(data.msg);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    cancelInfo() {
      this.$router.go(-1);
    },
  },
};
</script>
